import React from "react";
import styled from "styled-components";
import { AiFillInstagram, AiOutlineArrowUp } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { Slide, Zoom, Fade } from "react-awesome-reveal";

const Footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container id="footer">
      <Profile>
        <Slide direction="left" delay={1}>
          <h1>MV BARTENDERS</h1>
        </Slide>
        <div className="address">
          <Slide direction="left">
            <h1>Endereço:</h1>
            <p>Rua Ciro Melo, 3841 - Jardim Paulista</p>
            <p>Dourados - MS</p>
          </Slide>
        </div>
        <div className="links">
          <Slide direction="left">
            <h1>Fale Conosco:</h1>
            <div>
              <span>
                <FiPhoneCall />
              </span>
              <a href="tel:+5567999070709">+55 (67) 99907-0709</a>
            </div>
            <div>
              <span>
                <HiOutlineMailOpen />
              </span>
              <a href="mailto:mvbartendersms@gmail.com">
                mvbartendersms@gmail.com
              </a>
            </div>
          </Slide>
        </div>
        <div className="profiles">
          <Slide direction="left">
            <h1>Redes Sociais</h1>
            <div className="icons">
              <Zoom triggerOnce={true} threshold={0.1}>
                <span>
                  <a
                    href="https://www.instagram.com/mvbartenders_ms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram />
                  </a>
                </span>
              </Zoom>
            </div>
          </Slide>
        </div>
        <Fade>
          <ArrowUp onClick={scrollUp}>
            <AiOutlineArrowUp />
          </ArrowUp>
        </Fade>
      </Profile>
      <Form>
        <Slide direction="right">
          <h2>
            AGENDAR <span className="green">DEGUSTAÇÃO</span>
          </h2>
          <p>
            Agende uma degustação para experimentar nossos drinks. Atendimento
            de segunda a sexta, das 8h às 14h.
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=5567999070709&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20degusta%C3%A7%C3%A3o."
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <button style={{ margin: "1rem auto" }}>
              Agende sua degustação aqui!
            </button>
          </a>
        </Slide>
      </Form>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  margin-top: 2rem;
  position: relative;
  padding: 2rem 0;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

const Profile = styled.div`
  flex: 1;
  .address {
    padding: 1rem 0;
    h1 {
      font-size: 1.2rem;
    }

    p {
      width: 60%;
      padding-top: 0.5rem;
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .links {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      a {
        text-decoration: none;
        color: lightgray;
        :hover {
          color: orange;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      padding: 1rem 0;
    }

    .icons {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 50px;

        :hover {
          background-color: orange;
        }

        a {
          margin-top: 0.2rem;
          color: #fff;
        }
      }
    }
  }
`;

const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #8b7c05;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;

const Form = styled.div`
  flex: 1;
  h2 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
  }

  p {
    margin-top: 1rem;
    text-align: left;
  }

  button {
    width: 15rem;
    height: 2rem;
    background-color: #8b7c05;
    border: none;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 5px #8b7c0441);
    cursor: pointer;
    :hover {
      filter: drop-shadow(0px 6px 9px #01be9551);
    }
  }
  @media (max-width: 820px) {
    p {
      text-align: left;
    }
  }
`;
