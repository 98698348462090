import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Slide } from "react-awesome-reveal";
import styled from "styled-components";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import ReactInputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const schema = yup.object().shape({
  nomeCompleto: yup.string().required("Nome Completo é obrigatório"),
  comoConheceu: yup.string().required("Como nos conheceu é obrigatório"),
  dataEvento: yup
    .string()
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      "Data do Evento deve estar no formato DD/MM/AAAA"
    )
    .required("Data do Evento é obrigatória"),
  tipoEvento: yup.string().required("Tipo de Evento é obrigatório"),
  quantidadeConvidados: yup
    .number()
    .typeError("Quantidade de Convidados deve ser um número")
    .positive("Quantidade de Convidados deve ser um número positivo")
    .required("Qtd. de Convidados é obrigatória"),
  localEvento: yup.string().required("Local do Evento é obrigatório"),
  cidadeEvento: yup.string().required("Cidade é obrigatória"),
  ufEvento: yup.string().required("Estado é obrigatório"),
  telefone: yup
    .string()
    .matches(
      /^\(\d{2}\) \d{5}-\d{4}$/,
      "Telefone deve estar no formato (99) 99999-9999"
    )
    .required("Telefone é obrigatório"),
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
});

const SolicitarOrcamento = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    //resolver: yupResolver(schema),
    defaultValues: {
      nomeCompleto: "",
      comoConheceu: "",
      dataEvento: "",
      tipoEvento: "",
      quantidadeConvidados: "",
      localEvento: "",
      cidadeEvento: "",
      ufEvento: "",
      telefone: "",
      email: "",
      observacoes: "",
    },
  });

  const recaptchaRef = useRef(null);

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };

  const onSubmit = async (data) => {
    if (recaptchaRef.current) {
      const captchaValue = recaptchaRef.current.getValue();
      console.log("ReCAPTCHA Value:", captchaValue);

      if (!captchaValue) {
        alert("Por favor, verifique o reCAPTCHA!");
        return;
      }

      const url =
        "https://mvbartendersms.com.br:60034/send/af4e98d3-21b6-4e50-97df-7d0cda8578f4/mail";

      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers,
          body: JSON.stringify({
            from: data.email,
            to: "Contato MV BartendersMS",
            subject: "Solicitação de Orçamento",
            text: "",
            html: `
              <p><strong>Nome Completo:</strong> ${data.nomeCompleto}</p>
              <p><strong>Como Conheceu:</strong> ${data.comoConheceu}</p>
              <p><strong>Data do Evento:</strong> ${data.dataEvento}</p>
              <p><strong>Tipo de Evento:</strong> ${data.tipoEvento}</p>
              <p><strong>Quantidade de Convidados:</strong> ${data.quantidadeConvidados}</p>
              <p><strong>Local do Evento:</strong> ${data.localEvento}</p>
              <p><strong>Cidade:</strong> ${data.cidadeEvento}</p>
              <p><strong>Estado:</strong> ${data.ufEvento}</p>
              <p><strong>Telefone:</strong> ${data.telefone}</p>
              <p><strong>Email:</strong> ${data.email}</p>
              <p><strong>Observações:</strong> ${data.observacoes}</p>
            `,
          }),
        });

        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "E-mail enviado com sucesso!",
            text: "Recebemos sua solicitação. Nossa equipe está cuidando do seu pedido e entrará em contato em breve. Obrigado por escolher nossos serviços.",
            confirmButtonText: "OK",
            width: "80vw",
            heightAuto: true,
            background: "#191923",
            color: "#FFF",
            customClass: {
              confirmButton: "swal-confirm-button",
            },
            didOpen: () => {
              const popup = Swal.getPopup();
              popup.style.maxWidth = "80vw";
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.backgroundColor = "#8b7c05";
                confirmButton.style.color = "#fff";
              }
            },
          });

          reset();
          recaptchaRef.current.reset();
        } else {
          Swal.fire({
            icon: "success",
            title: "E-mail enviado com sucesso!",
            text: "Recebemos sua solicitação. Entraremos em contato em breve.",
            confirmButtonText: "OK",
            width: "80vw",
            heightAuto: true,
            background: "#191923",
            color: "#FFF",
            customClass: {
              confirmButton: "swal-confirm-button",
            },
            didOpen: () => {
              const popup = Swal.getPopup();
              popup.style.maxWidth = "80vw";
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.backgroundColor = "#8b7c05";
                confirmButton.style.color = "#fff";
              }
            },
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro inesperado",
          text: "Ocorreu um erro ao enviar o e-mail. Por favor, tente novamente mais tarde.",
          confirmButtonText: "OK",
        });
        console.error("Erro ao enviar o e-mail:", error);
      }
    } else {
      alert("ReCAPTCHA não está disponível.");
    }
  };

  return (
    <Form id="solicitarOrcamento">
      <Slide direction="right">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>
            SOLICITAR <span className="green">PROPOSTA</span>
          </h1>
          <Grid container spacing={1} rowSpacing={0} columnSpacing={1}>
            <Grid item xs={12} md={5}>
              <Controller
                name="nomeCompleto"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    placeholder="Digite Aqui..."
                    {...field}
                    error={!!errors.nomeCompleto}
                    helperText={
                      errors.nomeCompleto ? "Nome completo é obrigatório" : ""
                    }
                  />
                )}
                rules={{ required: "Nome Completo é obrigatório" }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name="comoConheceu"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.comoConheceu}>
                    <InputLabel>Como Nos Conheceu</InputLabel>
                    <Select
                      {...field}
                      label="Como Nos Conheceu"
                      defaultValue=""
                    >
                      <MenuItem value="">Selecione uma opção</MenuItem>
                      <MenuItem value="google">Google</MenuItem>
                      <MenuItem value="instagram">Instagram</MenuItem>
                      <MenuItem value="facebook">Facebook</MenuItem>
                      <MenuItem value="twitter">Twitter</MenuItem>
                      <MenuItem value="linkedin">LinkedIn</MenuItem>
                      <MenuItem value="outra">Outra</MenuItem>
                    </Select>
                    {errors.comoConheceu && (
                      <FormHelperText>
                        {errors.comoConheceu.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
                rules={{ required: "Como nos conheceu é obrigatório" }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name="dataEvento"
                control={control}
                render={({ field }) => (
                  <ReactInputMask
                    mask="99/99/9999"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        label="Data do Evento"
                        placeholder="00/00/0000"
                        error={!!errors.dataEvento}
                        helperText={errors.dataEvento?.message}
                      />
                    )}
                  </ReactInputMask>
                )}
                rules={{ required: "Data do Evento é obrigatória" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="tipoEvento"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Tipo de Evento</InputLabel>
                    <Select {...field} error={!!errors.tipoEvento}>
                      <MenuItem value="">Selecione o tipo de evento</MenuItem>
                      <MenuItem value="casamento">Casamento</MenuItem>
                      <MenuItem value="cha-cozinha">Chá de Cozinha</MenuItem>
                      <MenuItem value="noivado">Noivado</MenuItem>
                      <MenuItem value="cha-bar">Chá Bar</MenuItem>
                      <MenuItem value="aniversario">Aniversário</MenuItem>
                      <MenuItem value="corporativo">Corporativo</MenuItem>
                      <MenuItem value="aniversario-infantil">
                        Aniversário Infantil
                      </MenuItem>
                      <MenuItem value="despedida">Despedida</MenuItem>
                      <MenuItem value="outro">Outro</MenuItem>
                    </Select>
                    {errors.tipoEvento && (
                      <span>Tipo de Evento é obrigatório</span>
                    )}
                  </FormControl>
                )}
                rules={{ required: "Tipo de Evento é obrigatório" }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name="quantidadeConvidados"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Qtd. de Convidados"
                    placeholder="Digite Aqui..."
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="localEvento"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Local do Evento"
                    placeholder="Digite Aqui..."
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="ufEvento"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.ufEvento}>
                    <InputLabel>Estado</InputLabel>
                    <Select {...field} label="Estado" defaultValue="">
                      <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                    </Select>
                    {errors.ufEvento && (
                      <FormHelperText>{errors.ufEvento.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
                rules={{ required: "Estado é obrigatório" }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="cidadeEvento"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.cidadeEvento}>
                    <TextField
                      {...field}
                      label="Cidade"
                      error={!!errors.cidadeEvento}
                      helperText={errors.cidadeEvento?.message}
                    />
                  </FormControl>
                )}
                rules={{ required: "Cidade é obrigatória" }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="telefone"
                control={control}
                render={({ field }) => (
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        label="Telefone"
                        placeholder="Digite Aqui..."
                        error={!!errors.telefone}
                        helperText={errors.telefone?.message}
                      />
                    )}
                  </ReactInputMask>
                )}
                rules={{ required: "Telefone é obrigatório" }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    placeholder="Digite Aqui..."
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="observacoes"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Detalhes do Evento e Seleção de Drinks"
                    placeholder="Compartilhe mais informações sobre o seu evento e selecione os tipos de drinks que gostaria de ter."
                    multiline
                    rows={5}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className="captcha-button-container">
              <div className="recaptcha-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={handleCaptchaChange}
                  ref={recaptchaRef}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                disabled={!isCaptchaValid}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Slide>
    </Form>
  );
};

export default SolicitarOrcamento;

const Form = styled.div`
  margin-top: 3rem;
  flex: 1;

  h1 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }

  .recaptcha-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .captcha-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; /* Espaço entre o reCAPTCHA e o botão */
    margin-top: 1rem; /* Espaço acima do contêiner */
  }

  form {
    align-items: center;
    width: 70%;
    margin: 0 auto;
    background-color: #191923;
    padding: 1rem;
    border-radius: 5px;

    .green {
      color: #8b7c05;
    }

    .MuiInputLabel-root {
      color: white;
    }

    .MuiTextField-root,
    .MuiFormControl-root {
      margin-bottom: 0.2rem;

      .MuiOutlinedInput-root {
        fieldset {
          border-color: white;
        }
        &:hover fieldset {
          border-color: #8b7c05;
        }
        &.Mui-focused fieldset {
          border-color: #8b7c05;
        }
      }

      .MuiInputBase-root {
        color: white;
      }
    }

    .MuiSelect-root {
      color: white;
      .MuiOutlinedInput-root {
        fieldset {
          border-color: white;
        }
        &:hover fieldset {
          border-color: #8b7c05;
        }
        &.Mui-focused fieldset {
          border-color: #8b7c05;
        }
      }
    }

    .MuiMenuItem-root {
      color: black;
      background-color: white;
    }

    button {
      width: 7rem;
      height: 2rem;
      background-color: #8b7c05;
      border: none;
      border-radius: 5px;
      filter: drop-shadow(0px 4px 5px #8b7c0441);
      cursor: pointer;
      transition: filter 0.3s, opacity 0.3s;
      align-items: center;

      &:hover {
        filter: drop-shadow(0px 6px 9px #01be9551);
      }

      &:disabled {
        background-color: #8b7c05;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 840px) {
    form {
      width: 90%;
    }
  }

  .swal-confirm-button {
    background-color: #8b7c05; /* Cor de fundo desejada */
    color: #fff; /* Cor do texto */
    border-radius: 5px; /* Arredondamento do botão */
    padding: 10px 20px;
    border: none;
  }

  .swal-confirm-button:hover {
    background-color: #6c5b03; /* Cor do botão ao passar o mouse */
  }
`;
